

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/comment-debarrasser-partenaire-affaires-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost19 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Je veux me débarrasser de mon partenaire d’affaires, comment faire? - Soumissions Avocat"
        description="Vous désirer mettre fin à une relation d’affaires? Le temps est venu de consulter un avocat en droit des affaires pour amorcer la séparation!"
        image={LeadImage}>
        <h1>Je veux me débarrasser de mon partenaire d’affaires, comment faire?</h1>

					
					
						<p><strong>Tout partenariat commercial commence avec les bonnes intentions de part et d’autre</strong>. Cependant, les affaires étant les affaires, il arrive que la collaboration ne tienne plus la route et que vous souhaitiez ainsi mettre un terme à la relation avec votre partenaire. Pas si vite, vos associés disposent eux aussi de certains droits qu’il vous incombe de respecter lors du « divorce ».</p>
<p><StaticImage alt="comment debarrasser partenaire affaires" src="../images/comment-debarrasser-partenaire-affaires-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Rassurez-vous tout de suite, il existe tout de même maintes façons de vous débarrasser de votre partenaire d’affaires et ainsi repartir à neuf. Tout ce que vous avez à faire c’est de consulter un avocat spécialisé en droit commercial et des affaires afin de vous éclairer que l’étendue de vos recours!</p>
<p><strong>Soumissions Avocat est justement la plateforme de choix pour trouver un tel allié dans le monde des affaires, alors contactez-nous!</strong></p>
<h2>Comment utiliser la convention d’actionnaires pour se séparer d’un associé!</h2>
<p>La première étape à accomplir avant de songer à la séparation est d’ouvrir le document servant de convention d’actionnaires entre vous et votre partenaire. Cela est évidemment conditionnel au fait que vous opériez une entreprise incorporée sous forme de société par actions. Si tel est le cas et que vous aviez pris la peine de prévoir différentes clauses de retrait, le moment est venu de s’en servir!</p>
<p>Ce conseil s’applique également si vous n’avez pas encore prévu de convention entre actionnaires et que vous souhaitez prévoir les conditions d’une éventuelle séparation. En effet, le document servant de convention entre actionnaires est un contrat liant les actionnaires d’une entreprise en prévoyant l’étendue des droits de chacun ainsi que les modalités selon lesquelles ils peuvent quitter l’entreprise.</p>
<p>Si les clauses d’une convention d’actionnaires sont claires, cela ne signifie pas qu’elles soient simples à mettre en œuvre pour vous débarrasser de votre partenaire. Voici comment fonctionnent les diverses clauses d’exclusion retrouvées dans ce contrat.</p>
<h2>À quelles conditions pouvez-vous « tirer » sur la clause Shotgun?</h2>
<p>L’exercice d’une <a href="https://jurigo.ca/clause-shotgun-convention-actionnaires">clause Shotgun</a> représente la matérialisation de la phrase « ça passe ou ça casse. » À juste titre, la clause Shotgun est en réalité une disposition contractuelle faisant partie d’une convention d’actionnaires et dont le but est d’accélérer le règlement des conflits entre ces derniers. Son fonctionnement quoique fort simple s’avère cependant drastique.</p>
<p><StaticImage alt="clause shotgun expulser actionnaire" src="../images/clause-shotgun-expulser-actionnaire-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>L’actionnaire qui désire se prévaloir de la clause Shotgun doit offrir de vendre ses actions à l’autre actionnaire à un prix déterminé. Ce dernier se voit offrir deux options, soit acheter les actions proposées au prix stipulé, ou encore forcer l’actionnaire offrant racheter les parts de l’autre à ce même prix. D’une façon ou d’une autre, il ne reste plus qu’un seul « shérif en ville » à l’issue de l’exercice de la clause.</p>
<p><strong>Quand est-il possible d’exercer la clause Shotgun? </strong>Cela dépend de ce qui est prévu dans la convention d’actionnaires. Lorsque l’exercice de la clause n’est conditionnel à aucun évènement précis, l’actionnaire peut se prévaloir de ce droit à tout moment. Il est cependant possible de prévoir une condition préalable à l’exercice de cette clause.</p>
<p><strong>Quels sont les avantages de prévoir une clause Shotgun?</strong> Tout d’abord, en cas de conflit avec un partenaire d’affaire, la clause Shotgun permet d’éviter que le conflit ne s’éternise sur la méthode d’évaluation des actions, sur les modalités de paiement, sur lequel des actionnaires quittera, etc… Tout est prévu d’avance et la clause Shotgun crée une situation de type « tu pars ou je pars ».</p>
<p><strong>Comment la clause Shotgun vous aide-t-elle à vous débarrasser d’un partenaire d’affaires? </strong>En vous permettant de rapidement mettre fin à une relation entre actionnaires. Certes, le risque de vous voir racheter vos parts et de devoir vous-même quitter le navire est présent; c’est pourquoi il est impératif de recevoir les conseils d’un avocat avant de l’exercer.</p>
<h2>Droit de retrait, de premier refus et « Piggy back » : des clauses essentielles en affaires!</h2>
<p>Quand les choses vont mal entre des co-actionnaires ou des partenaires, tout un chacun tente de trouver la porte de sortie en vitesse. Cela peut s’avérer extrêmement risqué lorsque le droit de vendre les actions n’est pas limité par une convention d’actionnaires, puisque les actionnaires sont désormais libres de les vendre aux tiers de leur choix. Des clauses à cet effet doivent donc impérativement apparaître dans votre convention.</p>
<p>La première clause essentielle est celle de<strong> l’option d’achat en cas de retrait des affaires. </strong>Lorsqu’un actionnaire se voit dans l’impossibilité d’assumer ses fonctions, que ce soit en raison de l’invalidité ou de la retraite, les modalités de son départ doivent être prévues. La clause d’option d’achat en cas de retrait permet donc aux autres actionnaires de racheter les actions de ce dernier. <strong>
</strong>
Ensuite, la clause <strong>du droit de première offre </strong>est une disposition par laquelle un actionnaire désirant quitter l’entreprise se doit de faire parvenir une offre de vente aux autres actionnaires de la société avant de tenter de vendre à un tiers. Les actionnaires qui reçoivent l’offre ont ensuite le devoir d’accepter ou de refuser l’offre reçue à l’intérieur du délai stipulé dans la convention. En cas de refus, l’actionnaire offrant est libre de se tourner vers l’acheteur qu’il veut.<strong>
</strong>
En ce qui concerne<strong> le droit de premier refus, </strong>le fonctionnement s’apparente à celui de la clause de première offre. La différence est que l’actionnaire offrant doit d’abord obtenir une offre d’achat de la part d’un acheteur externe à la société à un prix et aux modalités déterminées. Ensuite, l’actionnaire désirant quitter doit offrir à ses co-actionnaires la possibilité d’acheter les actions en question au même prix et aux mêmes conditions que ce qui est stipulé dans l’offre d’achat.</p>
<p>Finalement, la clause d’entrainement plus souvent qualifiée de « <strong>clause Piggy back</strong> », est une disposition permettant de protéger les actionnaires minoritaires lors d’une acquisition par un tiers. Lorsqu’un actionnaire reçoit une offre d’achat, la clause d’entraînement peut soit l’obliger à forcer l’acheteur à acheter l’ensemble des actions de la société ou encore obliger les autres actionnaires de la société à céder leurs titres à ce même acheteur. L’intérêt d’une clause aussi drastique est qu’elle permet soit de prendre un contrôle accru de la société ou encore de vendre les actions pour un prix équitable pour tous les actionnaires.</p>
<p><strong>Ces clauses vous aident-elles réellement à vous débarrasser d’un partenaire d’affaires?</strong> Absolument. Chacune des clauses expliquées ci-haut permet d’accomplir un objectif précis, soit celui de quitter l’entreprise avec votre juste dû, ou encore expulser un partenaire d’affaire de façon équitable, mais surtout efficace!</p>
<h2>Que faire en cas de conflit si vous n’aviez pas conclu de convention?</h2>
<p>Rappelons-le encore une fois, votre partenaire d’affaires, s’il est actionnaire de l’entreprise, possède des droits qu’il vous incombe de respecter. La convention d’actionnaires sert justement à encadrer ces droits de façon à permettre l’exclusion de ce dernier lorsque l’harmonie ne règne plus. Alors que faire quand vous avez négligé de rédiger une telle entente?</p>
<p><StaticImage alt="conflit sans convention actionnaires" src="../images/conflit-sans-convention-actionnaires-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Le droit des actionnaires, </strong>en l’absence de disposition contraire, leur permet de demeurer dans la société de façon perpétuelle. C’est donc dire que vous ne pouvez pas expulser votre associé de l’entreprise pour des motifs purement arbitraires ou pour un conflit personnel. Il vous faudra invoquer un manquement à ses obligations légales, ce qui assure une chose : la survenance d’un litige.<strong>
</strong>
<strong>La médiation</strong> se présente donc comme alternative intéressante. À juste titre, étant donné que vous n’avez pas pris le temps de rédiger une convention entre actionnaires prévoyant les modalités de séparation, la médiation vous donnera la chance de le faire. Gardez toutefois en tête qu’il est beaucoup plus difficile de s’entendre sur les modalités de séparation avec un partenaire que vous tentez d’expulser que lorsque vous rédigez une convention à tête froide.<strong>
</strong>
Finalement,<strong> le litige </strong>implique que tous les actionnaires partis au conflit se munissent d’un avocat afin de les représenter. Des négociations devront être entamées afin de déterminer quel actionnaire rachètera les parts de l’autre, mais également pour établir la valeur à laquelle celles-ci seront vendues.</p>
<p>En effet, tandis qu’une convention d’actionnaires bien rédigée prendra soin de mentionner la méthode d’évaluation des actions en cas de conflit, les partenaires en situation de conflit, et dépourvus d’une convention préalable, n’auront pas d’autre choix que de s’entendre sur la méthode d’évaluation.</p>
<p><strong>Qu’arrive-t-il si aucun terrain d’entente ne peut être trouvé entre les partenaires?</strong> Vous l’aurez deviné, le chemin des tribunaux devra être emprunté et c’est devant le juge que chaque partenaire devra faire valoir ses droits et ses prétentions. Cela pourrait s’avérer extrêmement nuisible pour la profitabilité et la pérennité de l’entreprise.</p>
<p><strong>Clause <a href="https://jurigo.ca/avocat-mediation-arbitrage">d’arbitrage</a> commercial : est-ce le bon moyen de régler un conflit entre partenaires? </strong>Il s’agit effectivement d’une alternative viable au recours devant les tribunaux. Prenant place en privé, nul ne saura sur quoi porte l’objet du différend entre les partenaires et le règlement s’avère notamment plus rapide. Qui plus est, l’arbitre peut trancher le différend en appliquant le droit, mais également des principes d’équité, ce qui n’est pas le cas des juges devant les tribunaux.</p>
<h2>Séparation d’une société en nom collectif : quelle marche à suivre?</h2>
<p>La société en nom collectif, ou encore la « société de personnes », est une entité moins complexe que la société par actions. Étant régie par un simple contrat entre les associés, sa séparation s’avère souvent plus simple que celle d’un actionnaire. Encore une fois, cela n’est vrai que si vous avez pris la peine de rédiger votre entente par écrit…</p>
<p>Advenant que votre société en nom collectif soit constatée par contrat écrit, il sera simple de procéder au retrait d’un associé, car l’entente prendra soin de prévoir les modalités selon lesquelles un associé peut quitter la société. Il est d’ailleurs possible de restreindre ce droit dans le contrat lorsque la société a une durée de vie déterminée.</p>
<p>Advenant que vous n’ayez conclu aucun contrat de société ou que celui-ci soit muet quant aux droits des associés, sachez que ces derniers peuvent quitter en tout temps du moment qu’ils le font de bonne foi.</p>
<p><strong>Il est également possible d’expulser un associé de la société! </strong>Ce droit est effectivement prévu par la loi et permet d’exclure un associé qui ne respecte pas ses obligations à l’égard de la société. Ce serait notamment le cas d’un partenaire qui pose des actes nuisibles à la société ou à ses associés. Ce droit de retrait ne peut cependant être exercé que s’il existe un motif sérieux.</p>
<p>Par ailleurs, il est possible de demander le retrait judiciaire d’un associé lorsque ce dernier refuse de quitter à la demande des autres partenaires. Il faut toutefois s’assurer de disposer de motifs suffisamment sérieux au moment de déposer une telle demande, car le tribunal possède un large pouvoir discrétionnaire lui permettant d’expulser non seulement l’associé visé par la demande, mais également d’autres associés s’il est dans l’intérêt de la société de le faire.</p>
<p>Encore une fois, bien que de tels mécanismes existent, il est toujours préférable de les éviter en prévoyant d’avance, par l’entremise d’un contrat, la façon dont les conflits entre associés seront réglés, incluant les mécanismes de retrait et d’exclusion.</p>
<h2>Comment éviter qu’un conflit avec un partenaire d’affaires ne dégénère?</h2>
<p><strong>Prévoyez une convention d’actionnaires, c’est la base! </strong>À la lumière des différentes clauses qu’il vous est possible d’insérer dans la convention, il va de soi qu’aucun partenariat d’affaires prenant la forme d’une société par actions ne devrait s’en priver. Une chicane se règle nettement plus vite quand le mode de règlement de conflit est prévu à l’avance.</p>
<p><StaticImage alt="reglement conflit partenaires affaires" src="../images/reglement-conflit-partenaires-affaires-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Révisez cette même convention! </strong>Si vous êtes en affaires depuis le début des années 1980, il y a fort à parier que bien des choses ont changé depuis la rédaction de votre première convention d’actionnaires. Une convention désuète sera extrêmement difficile à appliquer et pourrait même compliquer la séparation. Dès que votre situation d’affaires change, prenez soin de mettre à jour votre convention.<strong>
</strong>
<strong>Maintenez la communication et gardez les relations courtoises en cas de séparation. </strong>Vous pensez qu’un divorce entre époux est complexe? Imaginez tenter de séparer des actifs d’entreprise valant plusieurs millions de dollars… Étant donné que vos intérêts financiers et ceux de vos partenaires sont importants, il est crucial de maintenir une relation adéquate lors de la séparation afin de s’assurer que chacun reçoive son dû sans que le tout ne dégénère en litige. Cela s’avérerait nuisible à la séparation sur le plan financier.</p>
<p><strong>Pourquoi est-il essentiel de prévenir l’aggravation du conflit? </strong>Parce qu’un conflit qui s’éternise est un conflit qui nuit au fonctionnement, à la profitabilité ainsi qu’à l’image de l’entreprise. Perdre des profits et des clients en raison d’une « chicane entre partenaires » est nuisible aux intérêts de toutes les parties impliquées.</p>
<p><strong>Au final, quel est le meilleur moyen d’éviter qu’une séparation entre partenaires d’affaires ne s’éternise?</strong> Vous consultez sans délai un avocat qualifié en droit des affaires, voilà comment! Celui-ci trouvera le moyen qui convient à votre situation personnelle afin de vous débarrasser de votre partenaire d’affaires une fois pour toutes.</p>
<h2>Mettez un terme à votre relation d’affaires en consultant un avocat partenaire de Soumissions Avocat!</h2>
<p><strong>L’heure est venue de vous séparer officiellement de votre partenaire d’affaires?</strong> Tous les mécanismes juridiques ont été mis en place pour assurer une séparation sans bavures? Dans ce cas, qu’attendez-vous pour exercer vos droits de retrait en consultant un avocat qualifié en matière commerciale et des affaires! Cela vous permettra de faire peau neuve et de repartir vos activités de façon indépendante.</p>
<p>Tel que mentionné, le meilleur moyen d’amorcer la séparation avec un partenaire d’affaires, c’est de confier votre dossier à un avocat en droit des affaires et commercial. Celui-ci vous conseillera quant aux meilleures clauses à exercer parmi celles prévues à votre convention d’actionnaires.</p>
<p><strong>Soumissions Avocat dispose d’un large nombre de partenaires qualifiés en droit des affaires partout en province. Il suffit de nous contacter pour entrer en contact avec eux.</strong></p>
<p><strong>En plus, notre mise en contact avec eux est gratuite et ne vous engage à rien!</strong></p>
<p>
					</p>
    </SeoPage>
)
export default BlogPost19
  